body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  /* height: 100vh; */
  font-family: Lato !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 
the following lines import the font for use
something to note is that the Black is thicker than the Bold, this is probably how the font was designed
*/

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-Regular.2d36b1a9.ttf);
  font-style: normal;
}

/* 
Use 700 for bold
Use 800 for extra bold
*/

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-Bold.85d339d9.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-Black.e631d273.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-Italic.7582e823.ttf);
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-BoldItalic.f98d1804.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-BlackItalic.2e26a916.ttf);
  font-weight: 800;
  font-style: italic;
}


.account {
  /* background: url("./b3.jpg") no-repeat; */
  /* background-size: cover; */
  /* border: 1px solid black; */
  /* height: 100vh; */
  width: 100%;
  /* color: white; */
}

#cert-reg{
  height: 205mm;
  width: auto;
  margin: 2em 0em;
}

#cert-tmp{
  height: 205mm;
  width: auto;
  margin: 2em 0em;
}

.auth-input{
  padding: 0em 1em;
}

.auth-input .form-label{
  float: left;
}

.btn-form{
  margin: .5em .5em;
}

.navbar-brand{
  padding-bottom: 0em !important;
}

#embed-cover{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* opacity: .25; */
  /* background-color: red; */
  height: 93%;
  /* width: 90%; */
}

#certFull{
  position: relative;
}

#brand-logo{
  height: 2em;
}

.terms{
  text-align: left;
  margin: 4em auto;
}

.btn-link {
  background-color: transparent;
  border: none;
}

.nav-item.top-nav a{
  /* font-size: 18pt; */
}

.img-fluid{
  max-height: 80vh;
}

@media(max-width: 768px){
  #embed-cover{
    height: 90%;
    width: 90%;
  }
}
.nav-item a {
    color: black;
    font-weight: normal;
}

#login-btn {
    background-color: #004684;
    border: none;
    width: 8em;
    font-weight: bold;
    border-radius: 20px;
}

.bar {
    padding: 0.5em 5em !important;
}

.bar {
    margin-bottom: 0 !important;
}

.bar {
    font-weight: bold;
}
.cert {
  height: 110vh;
  background: url(/static/media/cert.19b217dc.png) no-repeat;
  background-size: cover;
  margin-left: 15em;
}

.pc {
  width: 200vh;
  background: url(/static/media/b3.dc846958.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
}
#fname {
  font-size: 4em;
  /* padding-top: 4em; */
  /* padding-left: 1em; */
  position: absolute;
  top: 35%;
  /* bottom: 9.5em; */
  right: 0em;
  left: 0.25em;
}

#course {
  font-size: 3em;
  /* margin-top: 1em; */
  /* margin-left: 2em; */
  font-weight: bold;
  position: absolute;
  top: 50%;
  /* bottom: 11em; */
  right: 0em;
  left: 0.5em;
}

#sAdd {
  font-size: 12pt;
  /* margin-top: 20.5em;
  margin-left: 11.4em; */
  position: relative;
  bottom: 16em;
  right: 0em;
  left: 6.75em;
}

#txh {
  font-size: 11pt;
  /* margin-top: 0.5em;
  margin-left: 23.9em; */
  position: relative;
  bottom: 17.15em;
  right: 0em;
  left: 12.25em;
}

#id {
  font-size: 1.5em;
  /* margin-top: -0.8em;
  margin-left: -53em; */
  position: relative;
  bottom: 12em;
  right: 0em;
  left: -20em;
  /* text-align: left; */
}

.btn {
  margin-top: 10px;
}

#certContainer{
  padding: 2em;
}

#certContainer h1{
  color: white;
  padding: .5em 0em;
}

#cert{
  height: 100%;
  width: 100%;
  margin: 2em 0em;
}

#cert-contain{
  /* width: 227mm;
  height: 160mm; */
  
  width: auto;
  height: 195mm;
}

@media (max-width:1200px){
  #fname {
    top: 30%;
  }
  #course {
    top: 45%;
  }
}
@media (max-width:992px){
  #fname {
    font-size: 3em;
    top: 27%;
  }
  #course {
    font-size: 2em;
    top: 38%;
  }
}
.home {
    /* background: url("./b3.jpg") no-repeat; */
    /* background-size: cover; */
    /* border: 1px solid black; */
    height: 80%;
    max-width: 80%;
    color: black;
    margin: 3em 0em;
    text-align: left;
    padding: 0em 0em;
    margin-right: auto;
    margin-left: auto;
}

.home h1{
    color: #004684;
    font-size: 36pt;
    font-weight: bold;
    text-align: center;
}

.home h2{
    color: #004684;
    font-size: 24pt;
    font-weight: bold;
}

.home .quote_text{
    color: #004684;
    font-weight: bold;
    font-style: italic;
}

.home .quote_text2{
    font-weight: bold;
    font-style: italic;
}

.home p{
    font-size: 14pt;
}

#access-btn{
    background-color: #004684;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    padding: 1em;
}

.intro_video {
    -webkit-filter: drop-shadow(15px -15px 0px #2d487c);
            filter: drop-shadow(15px -15px 0px #2d487c);
    padding: 2em;
  }
/* #basic-navbar-nav{
    text-align: justify !important;
    width: 100%;
} */
#login-container {
    padding: 0em 7em;
    /* -ms-transform: translate(-50%, -50%); */
    -webkit-transform: translate(0%, 25vh);
            transform: translate(0%, 25vh);
}

#signup-container {
    padding: 0em 7em;
    /* -ms-transform: translate(-50%, -50%);
    transform: translate(0%, 25vh); */
}

#reg-img {
    background: url("/loginBG.jpg") no-repeat;
    background-size: cover;
    height: 100vh;
    color: white;
    align-items: center;
}

#reg-img-signup {
    background: url("/loginBG.jpg") no-repeat;
    background-size: cover;
    height: auto;
    color: white;
    align-items: center;
}

#reg-img #register-container {
    /* height: 95.6vh; */
    /* position: absolute; */
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

#reg-img-signup #register-container {
    /* height: 95.6vh; */
    /* position: absolute; */
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.account button {
    background-color: #004684;
    border: none;
}

.account {
    /* height: 100%;
    width: 100%; */
}

.btn-reg {
    /* height: 3em; */
    width: 12em;
    padding: .75em 0em !important;
    font-size: 16pt !important;
    font-weight: bold !important;
    background-color: #004684 !important;
    border: none !important;
}

.btn-reg:hover {
    background-color: #0069d9 !important;
}

.btn-login {
    height: 3em;
    width: 100%;
    font-size: 16pt !important;
    font-weight: bold !important;
    margin: 1em 0em !important;
}

.form-check input {
    -webkit-filter: drop-shadow(2px 1px 1px rgb(168, 168, 168));
            filter: drop-shadow(2px 1px 1px rgb(168, 168, 168));
}

.form-control {
    background-color: #F2F4F5 !important;
    border: none !important;
    height: 3.5em !important;
}

.account-form {
    margin: 1.5em 0em;
    background: rgba(116, 116, 116, 0.5)
}

.account-details {
    margin: 1.5em auto;
    padding: 2em 0em;
    background: rgba(116, 116, 116, 0.5);
    width: 50% !important;
}

.account-details h1 {
    margin: 0em 0em .5em 0em;
}

.account-details hr {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.col-sm-12{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media(max-width:1000px) {
    #login-container h1{
        font-size: 2em;
    }
    #login-container {
        padding: 0em 3em;
        -webkit-transform: translate(0%, 0vh);
                transform: translate(0%, 0vh);
    }
    #signup-container h1{
        font-size: 2em;
    }
    #signup-container {
        padding: 0em 3em;
        -webkit-transform: translate(0%, 0vh);
                transform: translate(0%, 0vh);
    } 
    #reg-img {
        height: auto;
    }
    #reg-img-signup {
        height: auto;
    }
}

@media(max-width:768px) {
    #reg-img {
        height: 100vh;
    }
    #reg-img-signup {
        height: 100vh;
    }
}
.footer {
    background-color: #004684;
    color: white;
    /* height: 10vh; */
}

.footer .nav-item a {
    color: white;
    font-weight: normal;
}

.footer .nav {
    padding: 2em 0em;
}

#copyright {
    background-color: #004684;
    padding: 0em 0em 1em 0em;
    font-size: 7pt;
}

.innovfin-logo{
    max-width: 150px;
  }

.footer_quote{
    font-style: italic;
    font-size: 12pt;
    padding: 1em 0em;
    margin: 0;
}
