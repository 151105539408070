.home {
    /* background: url("./b3.jpg") no-repeat; */
    /* background-size: cover; */
    /* border: 1px solid black; */
    height: 80%;
    max-width: 80%;
    color: black;
    margin: 3em 0em;
    text-align: left;
    padding: 0em 0em;
    margin-right: auto;
    margin-left: auto;
}

.home h1{
    color: #004684;
    font-size: 36pt;
    font-weight: bold;
    text-align: center;
}

.home h2{
    color: #004684;
    font-size: 24pt;
    font-weight: bold;
}

.home .quote_text{
    color: #004684;
    font-weight: bold;
    font-style: italic;
}

.home .quote_text2{
    font-weight: bold;
    font-style: italic;
}

.home p{
    font-size: 14pt;
}

#access-btn{
    background-color: #004684;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    padding: 1em;
}

.intro_video {
    filter: drop-shadow(15px -15px 0px #2d487c);
    padding: 2em;
  }
/* #basic-navbar-nav{
    text-align: justify !important;
    width: 100%;
} */