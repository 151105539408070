.footer {
    background-color: #004684;
    color: white;
    /* height: 10vh; */
}

.footer .nav-item a {
    color: white;
    font-weight: normal;
}

.footer .nav {
    padding: 2em 0em;
}

#copyright {
    background-color: #004684;
    padding: 0em 0em 1em 0em;
    font-size: 7pt;
}

.innovfin-logo{
    max-width: 150px;
  }

.footer_quote{
    font-style: italic;
    font-size: 12pt;
    padding: 1em 0em;
    margin: 0;
}