.App {
  text-align: center;
  /* height: 100vh; */
  font-family: Lato !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 
the following lines import the font for use
something to note is that the Black is thicker than the Bold, this is probably how the font was designed
*/

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Regular.ttf);
  font-style: normal;
}

/* 
Use 700 for bold
Use 800 for extra bold
*/

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Black.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-BlackItalic.ttf);
  font-weight: 800;
  font-style: italic;
}


.account {
  /* background: url("./b3.jpg") no-repeat; */
  /* background-size: cover; */
  /* border: 1px solid black; */
  /* height: 100vh; */
  width: 100%;
  /* color: white; */
}

#cert-reg{
  height: 205mm;
  width: auto;
  margin: 2em 0em;
}

#cert-tmp{
  height: 205mm;
  width: auto;
  margin: 2em 0em;
}

.auth-input{
  padding: 0em 1em;
}

.auth-input .form-label{
  float: left;
}

.btn-form{
  margin: .5em .5em;
}

.navbar-brand{
  padding-bottom: 0em !important;
}

#embed-cover{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* opacity: .25; */
  /* background-color: red; */
  height: 93%;
  /* width: 90%; */
}

#certFull{
  position: relative;
}

#brand-logo{
  height: 2em;
}

.terms{
  text-align: left;
  margin: 4em auto;
}

.btn-link {
  background-color: transparent;
  border: none;
}

.nav-item.top-nav a{
  /* font-size: 18pt; */
}

.img-fluid{
  max-height: 80vh;
}

@media(max-width: 768px){
  #embed-cover{
    height: 90%;
    width: 90%;
  }
}